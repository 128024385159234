import React from 'react';
import '../PortfolioPage.css'; // Custom CSS for Portfolio Page
import SearchableDropdown from './SearchableDropdown'; // Importing SearchableDropdown component

const handleLogout = () => {
  localStorage.removeItem('isAuthenticated');  // Remove authentication status
  window.location.href = '/login';  // Redirect to login page
};

const PortfolioPage = () => {
  return (
    <div className="portfolio-container">
      {/* Header Section */}
      <header className="header">
        <nav className="navbar">
          <div className="logo">MyPortfolio</div>
          <ul className="nav-links">
            <li><a href="#about">About</a></li>
            <li><a href="#skills">Skills</a></li>
            <li><a href="#projects">Projects</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
          {/* Log Out Button */}
          <button className="logout-btn" onClick={handleLogout}>Log Out</button>
        </nav>
      </header>

      {/* Main Section */}
      <main>
        {/* Hero Section */}
        <section id="hero" className="hero-section">
          <h1>Hi, I'm Brijesh</h1>
          <p>A Passionate React.js Developer</p>
          <a href="#projects" className="btn-primary">View My Work</a>
        </section>

        {/* Projects Section */}
        <section id="projects" className="projects-section">
          <h2>Projects</h2>
          <div className="project-list">
            <div className="project-item">
              <h3>Project 1</h3>
              <p>Description of the project with technologies used.</p>
              
              {/* Dropdown Menu Added */}
              <SearchableDropdown />
            </div>
            <div className="project-item">
              <h3>Project 2</h3>
              <p>Description of the project with technologies used.</p>
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="contact-section">
          <h2>Contact Me</h2>
          <p>Email: <a href="mailto:brijesh.b.gujarati@gmail.com">brijesh.b.gujarati@gmail.com</a></p>
          <p>Phone: 07436356824</p>
        </section>
      </main>

      {/* Footer Section */}
      <footer className="footer">
        <p>© {new Date().getFullYear()} Brijesh Gujarati. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default PortfolioPage;
