import React, { useState } from 'react';

const SearchableDropdown = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const options = ['Apple', 'Banana', 'Orange', 'Grapes', 'Pineapple', 'Mango'];

  const filteredOptions = options.filter(option =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setSearchTerm('');
  };

  return (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearchChange}
        placeholder="Search..."
        className="dropdown-search"
      />
      {searchTerm && (
        <ul className="dropdown-list">
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleOptionSelect(option)}
                className="dropdown-option"
              >
                {option}
              </li>
            ))
          ) : (
            <li>No matches found</li>
          )}
        </ul>
      )}
      {selectedOption && <div>You selected: {selectedOption}</div>}
    </div>
  );
};

export default SearchableDropdown;
