import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../Login.css'; // Custom CSS for Login Page

function LoginPage({ setIsAuthenticated }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = (event) => {
    event.preventDefault();

    // Dummy authentication (replace with real authentication logic)
    if (username === 'admin' && password === 'admin') {
      localStorage.setItem('isAuthenticated', 'true'); // Store in localStorage
      setIsAuthenticated(true); // Update authentication state
      navigate('/portfolio'); // Redirect to portfolio
    } else {
      alert('Invalid credentials');
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-heading">Login to Your Account</h2>
        <form onSubmit={handleLogin}>
          <div className="input-container">
            <input
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="input-field"
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="input-field"
            />
          </div>
          <button type="submit" className="login-btn">Log In</button>
        </form>
        <div className="footer-links">
          <a href="/forgot-password" className="forgot-password-link">Forgot Password?</a>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
